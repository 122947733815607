<template>
  <div>
    <!-- Begin mobile main menu -->
    <nav class="mob-main-mnu">
      <div class="mob-main-mnu-content">
        <ul class="mob-main-mnu-list">
          <li>
            <router-link to="what-we-do">What we do</router-link>
          </li>
          <!-- <li>
            <router-link to="cloud">Cloud</router-link>
            <div class="mmm-btn">
              <i class="material-icons md-24">expand_more</i>
            </div>
            <div class="mob-main-submnu">
              <div class="mob-main-submnu-item">
                <ul>
                  <li>
                    <router-link to="#">Cloud Development</router-link>
                  </li>

                  <li>
                    <router-link to="#">Corporate Solution</router-link>
                  </li>
                  <li>
                    <router-link to="#">Call Center Solutions</router-link>
                  </li>
                  <li>
                    <router-link to="#">Flights</router-link>
                  </li>
                  <li>
                    <router-link to="#">Accommodation</router-link>
                  </li>
                  <li>
                    <router-link to="#">Packages</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li> -->
          <!-- <li>
            <router-link to="/work">Work</router-link>
            <div class="mmm-btn">
              <i class="material-icons md-24">expand_more</i>
            </div>
            <div class="mob-main-submnu">
              <div class="mob-main-submnu-item">
                <ul>
                  <li>
                    <router-link to="goldentickettravel">Peter Goord Travel</router-link>
                  </li>
                  <li>
                    <router-link to="havenstar">Havenstar MMS</router-link>
                  </li>
                  <li>
                    <router-link to="holidaygems">HolidayGems</router-link>
                  </li>
                  <li>
                    <router-link to="irispayroll">IRIS Payroll Professional</router-link>
                  </li>
                  <li>
                    <router-link to="travelplaces">Travel Places</router-link>
                  </li>
                  <li>
                    <router-link to="sunmaster">Sunmaster Holidays</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li> -->
          <li>
            <router-link to="develop">Develop</router-link>
            <div class="mmm-btn">
              <i class="material-icons md-24">expand_more</i>
            </div>
            <div class="mob-main-submnu">
              <div class="mob-main-submnu-item">
                <ul>
                  <li>
                    <router-link to="vuejs">Vue.js</router-link>
                  </li>
                  <li>
                    <router-link to="umbraco">Umbraco</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <router-link to="contact-us">Get in touch</router-link>
          </li>
        </ul>
      </div>
      <!--   <div class="mob-main-mnu-footer">
        <ul class="mob-main-mnu-lang">
          <li class="active">
            <a href="#!">
              <span>En</span>
            </a>
          </li>
          <li>
            <a href="#!">
              <span>Sp</span>
            </a>
          </li>
          <li>
            <a href="#!">
              <span>It</span>
            </a>
          </li>
          <li>
            <a href="#!">
              <span>Uk</span>
            </a>
          </li>
          <li>
            <a href="#!">
              <span>Ru</span>
            </a>
          </li>
        </ul>
      </div>-->
    </nav>
    <!-- End mobile main menu -->
    <!-- Begin header -->
    <header class="header">
      <!-- Begin header fixed -->
      <nav class="header-fixed">
        <div class="container">
          <div
            class="row flex-nowrap align-items-center justify-content-between"
          >
            <div class="col-auto d-block d-lg-none header-fixed-col">
              <div class="main-mnu-btn">
                <span class="bar bar-1"></span>
                <span class="bar bar-2"></span>
                <span class="bar bar-3"></span>
                <span class="bar bar-4"></span>
              </div>
            </div>
            <div class="col-auto header-fixed-col">
              <!-- Begin logo -->
              <a href="/" class="logo logo-ico-widht-text" title="ObjectPower">
                <img
                  class="lazy logo-ico mr-0"
                  src="/img/logo-ico.jpg"
                  width="36"
                  height="35"
                  alt
                />
                <span class="logo-text">bjectPower</span>
              </a>
            </div>
            <div class="col-lg col-auto col-static header-fixed-col">
              <div
                class="row flex-nowrap align-items-center justify-content-end"
              >
                <div
                  class="col-auto header-fixed-col d-none d-lg-block col-static"
                >
                  <!-- Begin main menu -->
                  <nav class="main-mnu">
                    <ul class="main-mnu-list">
                      <!-- <li>
                        <router-link to="/what-we-do" data-title="What we do">
                          <span>What we do</span>
                        </router-link>
                      </li> -->
                      <!-- <li class="main-mnu-item-has-child">
                        <router-link to="cloud" data-title="Cloud">
                          <span>Cloud</span>
                          <i class="material-icons">expand_more</i>
                        </router-link>
                        <ul>
                          <li>
                            <router-link to="#" data-title="Cloud Development">
                              <span>Cloud Development</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link to="#" data-title="Corporate Solution">
                              <span>Corporate Solution</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link to="#" data-title="Call Center Solutions">
                              <span>Call Center Solutions</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link to="#" data-title="Flights">
                              <span>Flights</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link to="#" data-title="Accommodation">
                              <span>Accommodation</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link to="#" data-title="Packages">
                              <span>Packages</span>
                            </router-link>
                          </li>
                        </ul>
                      </li> -->
                      <!-- <li class="main-mnu-item-has-child">
                        <router-link
                          :to="{ name: 'develop' }"
                          data-title="Develop"
                        >
                          <span>Develop</span>
                          <i class="material-icons">expand_more</i>
                        </router-link>
                        <ul>
                          <li>
                            <router-link
                              :to="{ name: 'vuejs' }"
                              data-title="Vue.js"
                            >
                              <span>Vue.js</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link
                              :to="{ name: 'umbraco' }"
                              data-title="Umbraco"
                            >
                              <span>Umbraco</span>
                            </router-link>
                          </li>
                        </ul>
                      </li> -->
                      <!-- <li class="main-mnu-item-has-child">
                        <router-link :to="{ name: 'work' }" data-title="Work">
                          <span>Work</span>
                          <i class="material-icons">expand_more</i>
                        </router-link>
                        <ul>
                          <li>
                            <router-link
                              :to="{ name: 'goldentickettravel' }"
                              data-title="Peter Goord Travel"
                            >
                              <span>Peter Goord Travel</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'havenstar' }" data-title="Havenstar MMS">
                              <span>Havenstar MMS</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'holidaygems' }" data-title="HolidayGems">
                              <span>HolidayGems</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link
                              :to="{ name: 'irispayroll' }"
                              data-title="IRIS Payroll Professional"
                            >
                              <span>IRIS Payroll Professional</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link
                              :to="{ name: 'sunmaster' }"
                              data-title="Sunmaster Holidays"
                            >
                              <span>Sunmaster Holidays</span>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'travelplaces' }" data-title="Travel Places">
                              <span>Travel Places</span>
                            </router-link>
                          </li>
                        </ul>
                      </li> -->

                      <li>
                        <router-link to="contact" data-title="Get in touch">
                          <span>Get in touch</span>
                        </router-link>
                      </li>
                    </ul>
                  </nav>
                  <!-- End main menu -->
                </div>

                <div class="col-auto header-fixed-col col-static"></div>
                <div
                  class="col-auto d-block d-lg-none col-static header-fixed-col"
                >
                  <div class="header-navbar">
                    <div class="header-navbar-btn">
                      <i class="material-icons md-24">more_vert</i>
                    </div>
                    <ul class="header-navbar-content">
                       <router-link to="contact" data-title="Get in touch">
                          <span>Get in touch</span>
                        </router-link>
                      <!-- <li>
                        <a href="mailto:mail@example.com">
                          <i class="material-icons">email</i>
                          <span>mail@example.com</span>
                        </a>
                      </li>
                      <li>
                        <b>24/7 Support:</b>
                        <a href="#!" class="formingHrefTel">1-888-777-1234</a>
                      </li>
                      <li>
                        <a
                          href="#сallback_popup"
                          class="header-call-back-link сallback_popup_open"
                        >
                          <i class="material-icons">ring_volume</i>
                          <span>Callback</span>
                        </a>
                      </li> -->
                      <li>
                        <!-- Begin social links -->
                        <!-- <ul class="social-links">
                          <li>
                            <a href="#!" title="Facebook">
                              <svg viewBox="0 0 320 512">
                                <use xlink:href="img/sprite.svg#facebook-ico" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href="#!" title="Instagram">
                              <svg viewBox="0 0 448 512">
                                <use
                                  xlink:href="img/sprite.svg#instagram-ico"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href="#!" title="LinkedIn">
                              <svg viewBox="0 0 448 512">
                                <use
                                  xlink:href="img/sprite.svg#linkedin-in-ico"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href="#!" title="Twitter">
                              <svg viewBox="0 0 512 512">
                                <use xlink:href="img/sprite.svg#twitter-ico" />
                              </svg>
                            </a>
                          </li>
                        </ul> -->
                        <!-- End social links -->
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!-- End header fixed -->
    </header>
    <!-- End header -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>